.highlighted {
    background-color: red;
  }
  .react-calendar {
    background-color: transparent;
    color: white;
  }
  
  .react-calendar__tile {
    color: white;
  }
  .react-calendar__navigation button {
    color: white;
  }
  
  .react-calendar__navigation__label {
    color: white;
  }
  