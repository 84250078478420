/* Estilos para centrar */
.custom-header {
  display: flex;
  justify-content: space-between; /* Ajusta según tus necesidades */
  align-items: center;
  padding: 15px; /* Añade algún relleno si es necesario */
}

.logo-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-image {
  width: 100%; /* Ajusta el tamaño de la imagen al 100% del contenedor */
  height: auto; /* Ajusta la altura automáticamente para mantener las proporciones */
}

.social-icon {
  font-size: 24px; /* Ajusta el tamaño del icono según tus preferencias */
  margin-left: 15px; /* Ajusta el margen entre los iconos */
}
