@media (max-width: 768px) {
  .event-details {
    min-height: 150px; /* Puedes ajustar este valor según tus necesidades */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

/* eventbox.css */

.event-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background-color: transparent;
  color: white;
  border-color: white;
}

  